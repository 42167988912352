import service from "@/api/service";
import { GetResponse, RegisterResponse } from "@/api/talk-setting/response";
import { GetRequest, RegisterRequest } from "@/api/talk-setting/request";

/**
 * トーク詳細情報取得APIをコールします。
 *
 * @return GetResponse
 * @param getRequest
 */
export async function get(getRequest: GetRequest) {
  const response = await service.post("/get-talk-setting", getRequest);
  return response.data as GetResponse;
}

/**
 * トーク基本設定登録更新APIをコールします。
 *
 * @return RegisterResponse
 * @param registerRequest
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-talk-setting", registerRequest);
  return response.data as RegisterResponse;
}
