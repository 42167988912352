import { Component, Mixins, Prop } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import TalkSettingGet from "@/store/talk-setting/get";
import TalkSettingRegister from "@/store/talk-setting/register";
// @ts-ignore
import UlEmailField from "@/components/UlEmailField.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import Flash, { ErrorAlert } from "@/store/common/flash";
import { GetRequest, RegisterRequest } from "@/api/talk-setting/request";
import { GetMail, GetResult } from "@/api/talk-setting/response";
import { UlFileRegisiterTabType } from "@/utils/enums";
import WindowOpen from "@/utils/window-open";

@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    UIFormRow,
    UlFileRegister,
    UlEmailField
  }
})
export default class Edit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: true })
  id!: string;

  // ------------
  // 固定値
  // ------------
  // タイトル
  headingMain = "コミュニケーション";
  headingSub = "Communication";
  // 20210120 トーク機能追加 cyber 李 start
  breadCrumbs = [
    { text: "コミュニケーション", disabled: true },
    {
      text: "店舗一覧",
      disabled: false,
      to: { name: "communication" }
    },
    { text: "トーク基本設定", disabled: true }
  ];
  // 20210120 トーク機能追加 cyber 李 end
  // アップロード可能なファイルの種類
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  tabType = UlFileRegisiterTabType.Photo;

  // ------------
  // 変動値
  // ------------

  // 画像URL
  initIconImageUrl = null as string | null;
  initTalkImageUrl = null as string | null;

  // ローディングステータス
  isLoading = false;

  // 入力データ
  inputParams = {
    shopId: Number(this.id),
    isUse: 1,
    imageEditFlg: 0,
    image: null,
    imageId: null,
    isMail: 0,
    mail: [{}] as GetMail[],
    isPopup: 0,
    isSound: 0,
    initialImageEditFlg: 0,
    initialText: null,
    initialImageId: null,
    initialImage: null
  } as GetResult & RegisterRequest;

  /**
   * トーク機能を使用するか否かのフラグ
   */
  get isShowDetail() {
    return this.inputParams.isUse === 1;
  }

  /**
   * createdライフサイクルフック
   */
  async created() {
    await this.get();
    this.syncInputParams(TalkSettingGet.getDetail);
  }

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {
    await TalkSettingGet.clearResponse();
  }

  /**
   * アイコン画像をセットする
   */
  setIconFile(data: string, id: number | null) {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.image = data;
    this.inputParams.imageId = id;
  }

  /**
   * トーク画面の画像をセットする
   */
  setTalkFile(data: string, id: number | null) {
    this.inputParams.initialImageEditFlg = 1;
    this.inputParams.initialImage = data;
    this.inputParams.initialImageId = id;
  }

  /**
   * アイコン画像をリセットする
   */
  unsetIconFile() {
    this.inputParams.imageEditFlg = 1;
    this.inputParams.image = null;
    this.inputParams.imageId = null;
  }

  /**
   * トーク画面の画像をリセットする
   */
  unsetTalkFile() {
    this.inputParams.initialImageEditFlg = 1;
    this.inputParams.initialImage = null;
    this.inputParams.initialImageId = null;
  }

  /**
   * トーク基本情報の取得処理
   */
  async get(): Promise<boolean> {
    if (TalkSettingGet.isSuccess) {
      return true;
    }
    let request = { id: this.id } as GetRequest;
    await TalkSettingGet.get(request);
    if (!TalkSettingGet.isSuccess) {
      await Flash.setErrorNow({
        message: TalkSettingGet.getMessage,
        showReloadButton: true
      } as ErrorAlert);
    }
    return TalkSettingGet.isSuccess;
  }

  async preview() {
    await this.callRegisterApi(1);
    if (TalkSettingRegister.isSuccess) {
      WindowOpen.preview(TalkSettingRegister.getPreviewUrl);
    }
  }

  /**
   * トーク基本情報を更新する
   */
  async register() {
    await this.callRegisterApi(0);
    if (TalkSettingRegister.isSuccess) {
      await this.redirectWithSuccessAlert(
        "トーク基本情報を保存しました。",
        "/communication"
      );
    }
  }

  private async callRegisterApi(isPreview: number) {
    await Flash.clear();
    await TalkSettingRegister.register(this.createRegisterRequest(isPreview));
    if (!TalkSettingRegister.isSuccess) {
      await Flash.setErrorNow({
        message: TalkSettingRegister.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
  }

  /**
   * APIのレスポンスをdataにsyncする
   */
  private syncInputParams(getResult: GetResult) {
    this.inputParams = { ...this.inputParams, ...getResult };
    this.initIconImageUrl = getResult.image;
    this.initTalkImageUrl = getResult.initialImage;

    if (!this.inputParams.mail) {
      this.inputParams.mail = [{}] as GetMail[];
    }
  }

  private createRegisterRequest(isPreview: number) {
    const request = {} as RegisterRequest;
    request.isPreview = isPreview;
    request.shopId = this.inputParams.shopId;
    request.isUse = this.inputParams.isUse;
    request.isMail = this.inputParams.isMail;
    request.isPopup = this.inputParams.isPopup;
    request.isSound = this.inputParams.isSound;
    request.imageEditFlg = this.inputParams.imageEditFlg;
    request.image = this.inputParams.image;
    request.imageId = this.inputParams.imageId;
    request.initialText = this.inputParams.initialText;
    request.initialImageEditFlg = this.inputParams.initialImageEditFlg;
    request.initialImage = this.inputParams.initialImage;
    request.initialImageId = this.inputParams.initialImageId;
    if (request.imageEditFlg == 0 || request.imageId != null) {
      delete request.image;
    }
    if (request.initialImageEditFlg == 0 || request.initialImageId != null) {
      delete request.initialImage;
    }
    if (request.isMail === 1) {
      for (let i = 0; i < this.inputParams.mail.length; i++) {
        if (!request.mailId) {
          request.mailId = [];
        }
        request.mailId.push(this.inputParams.mail[i].id);

        if (!request.mailAddress) {
          request.mailAddress = [];
        }
        request.mailAddress.push(this.inputParams.mail[i].address);

        if (!request.mailConfirm) {
          request.mailConfirm = [];
        }
        request.mailConfirm.push(this.inputParams.mail[i].addressConfirm);

        if (!request.mailDispNum) {
          request.mailDispNum = [];
        }
        request.mailDispNum.push(i + 1);
      }
    }
    return request;
  }
}
